export const COLORS = {
  text: "#402f1c",
  accent: "#825c8d",
  lightText: "#f3f3f3",
};

// #a36e97 // light purple
// #825c8d // dark purple
// #f3f3f3 // light gray
// #8e867b // gray
// #402f1c //dark brown
