import * as S from "./style"
import { experience } from "../../data"

const Experience = () => {
  return (
    <S.Container id="experience" >
      <S.Titel>Experience</S.Titel>
      <S.Text>
        {experience.map((exp) => {
          return (
            <div style={{borderBottom: "2px solid grey", paddingBottom: 20, paddingTop: 20}}>
              <h5>{exp.jobTitle}</h5>
              <p>
                <span>
                  {exp.company}
                  {", "} {exp.period}
                </span>
              </p>
              {exp?.description && (
                <ul>
                  {exp?.description?.map((d) => (
                    <li>{d}</li>
                  ))}
                </ul>
              )}
              {exp?.assignments &&
                exp.assignments.map((assignment) => (
                  <div>
                    <p>{assignment.title}</p>
                    <ul>
                      {assignment.description.map((d) => (
                        <li>{d}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              <S.Technologies> Technologies: {exp.technologies.join(", ")}</S.Technologies>
              <S.Technologies>{exp.links.map((link)=>(
                <>
                <a href={link.link} style={{marginRight: 20}}>{link.title}</a>
                </>
              ))}</S.Technologies>
            </div>
          )
        })}
      </S.Text>
    </S.Container>
  )
}

export default Experience
