export const projectList = [
  // {
  //   name: "Skynet",
  //   description:
  //     "Working on designing and developing a full-stuck employee managment system during internship at Skymill.",
  //   image:
  //     "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/skynet-preview.png",
  //   faGithub: "",
  //   visitLink: "",
  //   companySite: "https://skymill.io/",
  //   tags: ["React", "TypeScript", "Tailwind"],
  // },
  {
    name: "Our Tasks",
    description: "Developing a project management system for several users",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/our-tasks.png",
    faGithub: "https://github.com/Sagva/our-tasks",
    visitLink: "https://ourtasks-46790.web.app/",
    tags: ["ReactJS", "JavaScript", "Firebase"],
  },
  {
    name: "Photo-review app",
    description: "Developing a web app using React, JavaScript and Firebase.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/photo-review-app.png",
    faGithub: "https://github.com/Sagva/firebase-memegram",
    visitLink: "https://foto-review-app-1dfd2.web.app/",
    tags: ["ReactJS", "JavaScript", "Firebase"],
  },
  {
    name: "MemeGram",
    description: "Developing a web app using ViteJS, JavaScript and Firebase.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/memegram.png",
    faGithub: "https://github.com/Sagva/firebase-memegram",
    visitLink: "http://elenamyadzeleva.com/memegram/",
    tags: ["ViteJS", "JavaScript", "Firebase"],
  },
  {
    name: "The game Connect-4",
    description:
      "Developing a web game Connect-4 using ReactJS and JavaScript.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/connect-4.jpg",
    faGithub: "https://github.com/Sagva/connect4",
    visitLink: "http://elenamyadzeleva.com/connect-4/",
    tags: ["ReactJS", "JavaScript"],
  },
  // {
  //   name: "Stockholm Open Digital",
  //   description:
  //     "Working on the mobile app Stockholm Open Digital in React Native  during internship at Redmind AB.",
  //   image:
  //     "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/stockholmOpen.png",
  //   faGithub: "",
  //   visitLink: "",
  //   companySite: "https://www.redmind.se/",
  //   tags: ["React Native", "TypeScript"],
  // },
  {
    name: "Interface to The Movie DB",
    description:
      "Creating a web app using ReactJS and React Query. The web app is an interface to The Movie DB (TMDB).",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/moviedb.jpg",
    faGithub: "https://github.com/Sagva/the-movie-db",
    visitLink: "http://elenamyadzeleva.com/the-movie-db/",
    tags: ["ReactJS", "React Query"],
  },
  {
    name: "Star Wars characters",
    description:
      "Creating a basic web app using ReactJS. The app displays Star Wars characters and provides information about them.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/star-wars.jpg",
    faGithub: "https://github.com/Sagva/star-wars",
    visitLink: "http://elenamyadzeleva.com/starwars/",
    tags: ["ReactJS"],
  },
  {
    name: "Booking system for a cinema",
    description:
      "Participating in a group project of developing App, a full stack booking system for a cinema with ReactJS, Node.js and MongoDb as a database.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/filmvisarna.png",
    faGithub: "https://github.com/Sagva/Film-Mbapppe",
    visitLink: "",
    tags: ["ReactJS", "Node.js", "MongoDb"],
  },
  {
    name: "Radio App",
    description:
      "Developing a Radio App with ReactJS, Node.js Express Server as a backend and SQlite as a database.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/radio-app.png",
    faGithub: "https://github.com/Sagva/radioapp",
    visitLink: "",
    tags: ["ReactJS", "Node.js", "SQlite"],
  },
  {
    name: "Carmarket",
    description:
      "Participating in a group project of developing a website with ReactJS.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/carmarket.jpg",
    faGithub: "https://github.com/Sagva/radioapp",
    visitLink: "http://elenamyadzeleva.com/carmarket/",
    tags: ["ReactJS"],
  },
  {
    name: "Todolist",
    description: "Developing a Todolist-App with Vue, Vue React, Vuex.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/todo-list.jpg",
    faGitlab: "https://gitlab.com/Sagva/vue-inlamningsuppgift-todolist",
    visitLink: "http://elenamyadzeleva.com/todo/",
    tags: ["Vue", "Vue React", "Vuex"],
  },
  // {
  //   name: "Our Transport",
  //   description:
  //     "Participating in a group project of designing interface of a website with Adobe XD and production of prototype with HTML, CSS and Bootstrap.",
  //   image:
  //     "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/our-transport.png",
  //   faGitlab: "",
  //   visitLink: "http://elenamyadzeleva.com/ourtransport/index.html",
  //   tags: ["Adobe XD", "HTML", "CSS", "Bootstrap"],
  // },
  {
    name: "Quiz",
    description: "Developing a quiz with HTML, CSS and JavaScript.",
    image:
      "https://s3.eu-north-1.amazonaws.com/elenamyadzeleva.com/projects-previews/quiz.png",
    faGitlab: "https://gitlab.com/Sagva/inlamningsuppgift-js-1-quiz",
    visitLink: "http://elenamyadzeleva.com/quiz",
    tags: ["HTML", "CSS", "JavaScript"],
  },
]

export const experience = [
  {
    jobTitle: "Front End Developer",
    company: "Nygen Analytics",
    period: "Jun 2023 - Ongoing",
    description: [
      "Developing and maintaining the Scarf web application using ReactJS, TypeScript, and Tailwind",
    ],
    technologies: ["ReactJS", "TypeScript", "Github"],
    links: [
      {
        title: "Nygen Analytics",
        link: "https://www.nygen.io/",
      },
      {
        title: "Scarf Web",
        link: "https://scarfweb.app/",
      },
    ],
  },

  {
    jobTitle:
      "IT Consultant specialized in frontend development with expertise in React and TypeScript.",
    company: "New Default",
    period: "Jun 2022 - Jun 2023 - (12 months)",
    assignments: [
      {
        title: "Assignment at Nygen Analytics (June 2022 - June 2023):",
        description: [
          "Developed and maintained the Scarf web application using ReactJS, TypeScript, and Tailwind.",
          "Created interactive graphs and diagrams with Plotly.js to ensure user experience is optimized.",
          "Wrote comprehensive end-to-end tests with Playwright.",
          "Created intuitive onboarding guides for users with the React JoyRide library.",
          "Implemented new features and ensured the web application was responsive.",
          "Optimized the web application for better performance and scalability.",
        ],
      },
    ],
    technologies: [
      "ReactJS",
      "TypeScript",
      "Tailwind",
      "Github",
      "Plotly.js",
      "Playwright",
      "React JoyRide",
    ],
    links: [
      {
        title: "New Default",
        link: "https://newdefault.se/",
      },
      {
        title: "Nygen Analytics",
        link: "https://www.nygen.io/",
      },
      {
        title: "Scarf Web",
        link: "https://scarfweb.app/",
      },
    ],
  },

  {
    jobTitle: "Front End Developer Intern",
    company: "Skymill",
    period: "Jan 2022 - May 2022 (5 months)",
    description: [
      "Designed and developed a part of an employee management system using ReactJS, NodeJs, TypeScript, MySQL, Prisma ORM, and Tailwind.",
      "Developed skills in user interface design for web applications, writing documentation, and designing a database structure.",
      "Wrote unit tests using React testing library",
    ],
    technologies: [
      "ReactJS",
      "NodeJs",
      "TypeScript",
      "MySQL",
      "Prisma ORM",
      "Tailwind",
      "Github",
    ],
    links: [
      {
        title: "Skymill",
        link: "https://skymill.io/",
      },
      
    ],
  },
  {
    jobTitle: "Front End Developer Intern",
    company: "Redmind",
    period: "Sep 2021 - Nov 2021 (3 months)",
    description: [
      "Worked on a React Native Mobile App. Learned best practices for mobile application development using React Native.",
    ],
    technologies: ["React Native", "TypeScript", "Github"],
    links: [
      {
        title: "Redmind",
        link: "https://www.redmind.se/",
      },
      
    ],
  },
]
