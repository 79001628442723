import CarouselComponent from "../CarouselComponent";
import * as S from "./style";

const Projects = () => {
  return (
    <S.Container id="projects" style={{paddingTop: 0}}>
      <S.Titel>Projects</S.Titel>
      <S.Text>
      As a student of the Front-end developer course at Medieinstitutet, I have been working on several projects that have helped me develop my skills and knowledge in this field. Some of the projects I have been working on:
      </S.Text>
      <CarouselComponent />
    </S.Container>
  );
};

export default Projects;
