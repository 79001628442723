import styled from "styled-components";
import { COLORS } from "../Colors/Color";

export const FooterContainer = styled.div`
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to right bottom, #6f82ba, #96829d);

  @media (min-width: 400px) {
  }
`;
export const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const Link = styled.a`
  text-decoration: none;
`;
export const SocialMedia = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
`;
export const Name = styled.p`
  color: ${COLORS.lightText};
  margin-left: 10px;
  ${Link}:hover & {
    text-shadow: 0 0 8px rgb(255, 255, 255);
  }
`;
export const CityYear = styled.div`
  color: ${COLORS.lightText};
  margin-top: 10px;
`;
