import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  padding: 50px 10px;
  max-width: 1200px;
`;
export const Titel = styled.h2`
  text-align: center;
  margin-bottom: 15px;
  @media (min-width: 400px) {
    margin-bottom: 30px;
  }
`;
export const CourseTitel = styled.h4`
  margin-top: 20px;
  text-align: center;
`;
export const Text = styled.p`
  padding: 10px;
  font-size: 16px;
  
  @media (min-width: 400px) {
    padding: 20px;
    font-size: 20px;
  }
`;

export const EducationItem = styled.div`
  display: flex;

  padding: 10px 20px;
  font-size: 16px;
  @media (min-width: 400px) {
    font-size: 20px;
    flex-direction: row;
  }
`;
export const EducationYear = styled.div`
  width: 120px;
  @media (min-width: 600px) {
    width: 150px;
  }
`;
export const EducationDescription = styled.div`
  @media (min-width: 600px) {
    min-width: 400px;
  }
`;
export const Technologies = styled.p`
  font-size: 16px;
  font-style: italic;
`;
