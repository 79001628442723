import React from "react";
import Header from "../Components/Header/Header";
import NavbarComponent from "../Components/NavbarComponent";
import Experience from "../Components/Experience/Experience";
import Education from "../Components/Education/Education";
import Footer from "../Footer/Footer";
import Projects from "../Components/Projects/Projects";

const Home = () => {
  return (
    <div>
      <NavbarComponent />
      <Header />
      <Experience />
      <Projects/>
      <Education />
      <Footer />
    </div>
  );
};

export default Home;
