import * as S from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const socialMedia = [
    {
      name: "Linkedin",
      icon: faLinkedin,
      link: "https://www.linkedin.com/in/elena-myadzeleva-b04a38169/",
    },
    {
      name: "Facebook",
      icon: faFacebook,
      link: "https://www.facebook.com/elena.myadzeleva.5/",
    },
    {
      name: "Github",
      icon: faGithub,
      link: "https://github.com/Sagva",
    },
  ];
  return (
    <S.FooterContainer>
      <S.SocialMediaWrapper>
        {socialMedia.map((item, index) => {
          return (
            <S.Link target="_blank" href={item.link} key={index}>
              <S.SocialMedia>
                <FontAwesomeIcon
                  size="lg"
                  icon={item.icon}
                  style={{ color: "white" }}
                />

                <S.Name>{item.name}</S.Name>
              </S.SocialMedia>
            </S.Link>
          );
        })}
      </S.SocialMediaWrapper>
      <S.CityYear>Malmö, 2020 - {new Date().getFullYear()}{" "}</S.CityYear>
    </S.FooterContainer>
  );
};

export default Footer;
