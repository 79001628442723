import * as S from "./style";
import arrowDown from "../../Icons/arrowDown.svg";

const Header = () => {
  return (
    <S.Header>
      <S.InfoCntainer>
        <div>
          <S.Image src="../assets/photo.jpg" alt="me" />
          <S.Arrow href="#experience">
          
            <img src={arrowDown} width={50} height={50} alt="arrow icon" />
          </S.Arrow>
          
        </div>

        <S.TextContainer>
          <S.H1>Hi! I'm Elena</S.H1>
          <S.Text>
          I am a frontend developer with an interest in ReactJS and experience in building web apps, web design, and developing layouts with HTML and CSS (Tailwind) as well as JavaScript/TypeScript programming. I enjoy working as a web developer and I like projects where I can both contribute to improving it and at the same time, improve my skills.
          </S.Text>
        </S.TextContainer>
      </S.InfoCntainer>
    </S.Header>
  );
};

export default Header;
