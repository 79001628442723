import * as S from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGitlab, faGithub } from "@fortawesome/free-brands-svg-icons";

const CardComponent = ({ item }) => {
  return (
    <S.Card>
      <S.ImageBox>
        {item.visitLink ? (
          <a target="_blank" rel="noreferrer" href={item.visitLink}>
            <S.Image src={item.image} alt={`${item.name}`} />
          </a>
        ) : (
          <S.Image src={item.image} alt={`${item.name}`} />
        )}
      </S.ImageBox>
      <S.CardBody>
        <S.Title>{item.name}</S.Title>
        <S.Text>{item.description}</S.Text>
        <S.TagsContainer>
          {item.tags.map((tag, index) => {
            return <S.Tag key={index}>{tag}</S.Tag>;
          })}

          {item.faGitlab && (
            <S.GitContainer>
              <a target="_blank" rel="noreferrer" href={item.faGitlab}>
                <FontAwesomeIcon
                  size="lg"
                  icon={faGitlab}
                  style={{ color: "#402f1c" }}
                />
              </a>
            </S.GitContainer>
          )}
          {item.faGithub && (
            <S.GitContainer>
              <a target="_blank" rel="noreferrer" href={item.faGithub}>
                <FontAwesomeIcon
                  size="lg"
                  icon={faGithub}
                  style={{ color: "#402f1c" }}
                />
              </a>
            </S.GitContainer>
          )}
        </S.TagsContainer>

        <S.LinkContainer>
          {(item.visitLink || item.companySite) && (
            <S.LinkToProject
              target="_blank"
              rel="noreferrer"
              href={item.visitLink ? item.visitLink : item.companySite}
            >
              See the project
            </S.LinkToProject>
          )}
        </S.LinkContainer>
      </S.CardBody>
    </S.Card>
  );
};

export default CardComponent;
