import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardComponent from "./CardComponent/CardComponent";
import { projectList } from "../data";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const CarouselComponent = () => {
  return (
    <div>
      <Carousel
        style={{ padding: "20px 10px" }}
        //   swipeable={false}
        //   draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={true}
        //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
        //   autoPlaySpeed={1000}
        //   keyBoardControl={true}
        //   customTransition="all .5"
        //   transitionDuration={500}
        //   containerClass="carousel-container"
        // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        //   deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        //   itemClass="carousel-item-padding-40-px"
      >
        {/* <div>Item 1</div>
          <div>Item 2</div>
          <div>Item 3</div>
          <div>Item 4</div> */}

        {projectList.map((item, index) => {
          return <CardComponent key={index} item={item} />;
        })}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
