import * as S from "../Experience/style";
import check from "../../Icons/check.svg";

const Education = () => {
  return (
    <div id="education" style={{ backgroundColor: "#e0dbdb44" }}>
      <S.Container>
        <S.Titel>Education</S.Titel>
        <div>
          <S.EducationItem>
            <S.EducationYear>2020 &#8208; 2022</S.EducationYear>
            <S.EducationDescription>
              Malmö Medieinstitutet, Front-end developer
            </S.EducationDescription>
          </S.EducationItem>
          <S.CourseTitel>Courses</S.CourseTitel>
          <S.EducationItem>
            <img src={check} width={30} height={30} alt="arrow icon" />
            <S.EducationDescription>
              Online-course (Udemy.com), The Complete JavaScript Course
            </S.EducationDescription>
          </S.EducationItem>
          <S.EducationItem>
            <img src={check} width={30} height={30} alt="arrow icon" />
            <S.EducationDescription>
              Online-course (Udemy.com), Course «Advanced CSS and Sass»
            </S.EducationDescription>
          </S.EducationItem>
        </div>
      </S.Container>
    </div>
  );
};

export default Education;
