import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./Pages/Home";
function App() {
  return (
    <div>
      <Home />
    </div>
  );
}

export default App;
