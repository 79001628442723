import styled from "styled-components";
import img from "./hero-img.jpg";
import { COLORS } from "../../Colors/Color";
// height: calc(100vh - 110px);
export const Header = styled.div`

  
  background-image: url(${img});
  imgUrl={process.env.PUBLIC_URL + '/banner.png'}
  background-repeat: no-repeat;
  background-size:cover;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 900px) {
    height: calc(100vh - 110px);
  }
  
`;

export const InfoCntainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  max-width: 1200px;

  @media (min-width: 900px) {
    padding: 20px 30px;
    flex-direction: row;
  }
`;
export const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
  position: relative;
  @media (min-width: 900px) {
    margin-right: 20px;
  }
  @media (min-width: 1200px) {
    margin-right: 60px;
    width: 300px;
    height: 300px;
  }
`;
export const Arrow = styled.a`
  display: none;
  @media (min-width: 900px) {
    display: block;
    position: absolute;
    bottom: 1%;
    right: 50%;
  }
`;
export const TextContainer = styled.div`
  background-image: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.2)
  );
  padding: 20px;
  border-radius: 10px;
  @media (min-width: 1200px) {
    padding: 20px 60px;
    line-height: 1.8;
  }
`;

export const Text = styled.p`
  color: ${COLORS.text};

  @media (min-width: 400px) {
    font-size: 20px;
  }
  @media (min-width: 900px) {
    font-weight: light;
  }
  @media (min-width: 1400px) {
    font-size: 20px;
  }
`;

export const H1 = styled(Text)`
  font-size: 24px;
  text-align: center;
  @media (min-width: 1200px) {
    font-size: 36px;
  }
`;
