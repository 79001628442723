import styled from "styled-components";
import { COLORS } from "../../Colors/Color";

export const Card = styled.div`
  min-width: 280px;
  margin: 0 20px;
  background-color: #eae8e6;
  border-radius: 5px;
  border: 2px solid #e6e1e1;
  transition: all 0.4s;
  &:hover {
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    background-color: white;
  }
`;
export const ImageBox = styled.div`
  background: black;
  overflow: hidden;
`;
export const Image = styled.img`
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  opacity: 0.9;
  transform: scale(1.06);
  transition: all 0.4s;

  ${Card}:hover & {
    transform: scale(1.02);
    opacity: 1;
  }
`;

export const CardBody = styled.div`
  height: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const Title = styled.p`
  font-weight: bold;
  text-align: center;
  flex-grow: 0;
`;
export const Text = styled.p`
  height: 120px;
`;

export const TagsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 20px;
`;
export const Tag = styled.div`
  font-size: 12px;
  padding: 5px 8px;
  background: linear-gradient(to right bottom, #96829d, #7e6487);

  margin-right: 10px;
  color: ${COLORS.lightText};
  border-radius: 10px;
`;
export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;
export const LinkToProject = styled.a`
  text-decoration: none;
  color: ${COLORS.lightText};
  background: linear-gradient(to right bottom, #9198e5, #96829d);
  transition: all 0.5s;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  opacity: 0.9;
  box-shadow: 0 5px 10px rgba(0,0,0,0.3);

  &:hover {
    background: linear-gradient(to right bottom, #6f82ba, #7e6487);
    box-shadow: 0 5px 10px rgba(0,0,0,0.5);
    color: ${COLORS.lightText};
    opacity 1
  }
`;
export const GitContainer = styled.div`
  background-color: ${COLORS.lightText};
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
  border: 1px solid transparent;

  &:hover {
    background-color: #e0dedc;
    border: 1px solid gray;
  }
`;
